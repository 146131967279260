import api from '@/services/api'

const RESOURCE_URI = '/rent'

const fetchRents = () => api.get(RESOURCE_URI)
const fetchRentById = (id) => api.get(`${RESOURCE_URI}/${id}`)
const createRent = (rent) => api.post(RESOURCE_URI, rent)
const updateRent = ({
  id,
  toyId,
  periodId,
  customerId,
  userId,
  storeId,
  notes,
  status
}) =>
  api.put(`${RESOURCE_URI}/${id}`, {
    id,
    toyId,
    periodId,
    customerId,
    userId,
    storeId,
    notes,
    status
  })
const removeRent = (id) => api.delete(`${RESOURCE_URI}/${id}`)

const startRent = ({ toyId, periodId, storeId, customer }) =>
  api.post(`${RESOURCE_URI}/start`, {
    toyId,
    periodId,
    storeId,
    customer
  })

const pauseRent = ({ id }) => api.post(`${RESOURCE_URI}/${id}/pause`)

const resumeRent = ({ id }) => api.post(`${RESOURCE_URI}/${id}/resume`)

const closeRent = ({ id, checkoutId, payments }) =>
  api.post(`${RESOURCE_URI}/${id}/close`, { checkoutId, payments })

const transferRent = ({ id, toyId }) =>
  api.post(`${RESOURCE_URI}/${id}/transfer`, { toyId })

const cancelRent = ({ id, reason }) =>
  api.post(`${RESOURCE_URI}/${id}/cancel`, { reason })

const addExtraTime = ({ id, time, reason }) =>
  api.post(`${RESOURCE_URI}/${id}/extra-time`, { time, reason })

const reopenLastRent = ({ id }) => api.post(`${RESOURCE_URI}/${id}/reopen`)

const changePeriod = ({ id, periodId }) =>
  api.post(`${RESOURCE_URI}/${id}/change-period`, { periodId })

const addProduct = ({ id, productId, quantity }) =>
  api.post(`${RESOURCE_URI}/${id}/product`, { productId, quantity })

const removeProduct = ({ id, productId }) =>
  api.delete(`${RESOURCE_URI}/${id}/product/${productId}`)

export default {
  fetchRents,
  fetchRentById,
  createRent,
  updateRent,
  removeRent,
  startRent,
  pauseRent,
  resumeRent,
  closeRent,
  transferRent,
  cancelRent,
  addExtraTime,
  reopenLastRent,
  changePeriod,
  addProduct,
  removeProduct
}
